import './styles/global.scss'
import './js/components/_1_anim-menu-btn.js'
import './js/components/_1_menu.js'
// import './js/components/_1_modal-window.js'
// import './js/components/_1_rating.js'
// import './js/components/_1_reveal-effects.js'
// import './js/components/_1_swipe-content.js'
import './js/components/_2_flexi-header.js'
import './js/components/_2_menu-bar.js'
// import './js/components/_2_slideshow.js'
// import './js/components/_3_expandable-img-gallery.js'

import PhotoSwipeLightbox from 'photoswipe/lightbox';

const lightbox = new PhotoSwipeLightbox({
    gallery: '.photoswipe',
    children: 'a',
    pswpModule: () => import('photoswipe')
});
lightbox.init();